import React from 'react';
import { graphql } from 'gatsby';
import { useEffect } from 'react';
import Layout from '@components/Layout/Layout';
import SEO from '@components/seo';
import { navigate } from 'gatsby';
import Flex from '@common/Flex';
import IFrame from '@common/IFrame';
import Button, { IconButton } from '@common/Button';

import { ProjectLinks } from '@components/Projects/ProjectTemplate.style';
import SocialShareSection from '@components/Blog/SocialShareSection';
import SplitLayout from '@components/common/SplitLayout';

import { InfoTitle, CaseStudyWrapper } from './case-study.style';

const CaseStudy = ({ data }) => {
  const baseSlugUrl = 'https://www.seelay.in' + data.markdownRemark.fields.slug;
  const study = data.markdownRemark.frontmatter;

  const infoLinks = study.info.links && (
    <div>
      <InfoTitle>Links</InfoTitle>
      <ul>
        {study.info.links.map((link, i) => (
          <li key={i}>
            <a target="blank" href={link[1]}>
              {link[0]}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );

  useEffect(() => {
    window.addEventListener(
      'contextmenu',
      function(e) {
        // do something here...
        e.preventDefault();
      },
      false
    );
  }, []);

  return (
    <Layout>
      <SEO slug={data.markdownRemark.fields.slug} title={study.title} />
      <CaseStudyWrapper>
        <Flex className="case__title" justify="space-between" align="center">
          <h1>{study.title}</h1>

          <ProjectLinks className="case__links">
            <Button onClick={() => navigate(-1)}>Go Back</Button>
            {/* <IconButton
              label="github"
              icon={['fab', 'github']}
              href={study.src}
            /> */}
          </ProjectLinks>
        </Flex>

        <section className="case__info">
          <div>
            <aside>
              <InfoTitle>Overview</InfoTitle>
              <p>{study.info.overview}</p>
            </aside>
            <aside>
              <InfoTitle>Role</InfoTitle>
              <ul>
                {study.info.role.map((role, i) => (
                  <li key={i}>{role}</li>
                ))}
              </ul>
            </aside>
            {infoLinks}
          </div>
          <div className="case__iframe-container">
            <IFrame src={study.iframe} />
          </div>
        </section>

        <SplitLayout
          content={
            <article
              className="markdown-content"
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            />
          }
          aside={
            <div>
              <h4>Share on</h4>
              <SocialShareSection
                baseSlugUrl={baseSlugUrl}
                title={study.title}
              />
              <hr style={{ margin: '25px 0' }} />
            </div>
          }
        />
      </CaseStudyWrapper>
    </Layout>
  );
};

export const query = graphql`
  query caseStudyBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        iframe
        behance

        info {
          overview
          role
          links
        }
      }
    }
  }
`;

export default CaseStudy;
